@import "./colors";
@import "./fonts";
@import "./variables";

.ui-tree {
  width: 100% !important;
  color: $blue-univ !important;
  border: hidden !important;
  background-color: inherit !important;
}

.ui-treenode-content:focus {
  color: $brown-univ !important;
  box-shadow: none !important;
}

.ui-state-active {
  color: $font-light !important;
  background-color: $blue-univ !important;
}

.ui-paginator {
  background-color: white !important;
  color: $font-dark !important;
  // box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5) !important;
}

.ui-paginator-page:focus {
  color: $font-light !important;
  background-color: $blue-univ !important;
  box-shadow: none !important;
}

.ui-table {
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5) !important;
}

.ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: inherit !important;
  color: inherit !important;
}

.ui-table-caption {
  background-color: white !important;
  color: $font-dark;
}

.ui-fileupload .ui-fileupload-buttonbar .ui-button {
  background-color: $blue-univ;
  border-color: $blue-univ;
}

.ui-fileupload .ui-fileupload-buttonbar .ui-button:hover {
  background-color: $brown-univ;
  border-color: $brown-univ;
}

.ui-sortable-column.ui-state-highlight {
  background-color: $blue-univ !important;
  color: $font-light !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  color:$blue-univ !important;
  background: $blue-light-univ !important;
  border-color: $blue-light-univ !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  box-shadow: 0 0 0 0.2rem $blue-light-univ !important;
}

.p-datatable .p-datatable-header{
  height:50px !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background: $blue-univ !important;
  color: #ffffff !important;
}

.p-datatable .p-sortable-column.p-highlight:active {
  background: $blue-univ !important;
  color: #ffffff !important;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: $blue-univ !important;
  color: #ffffff !important;
}

.p-sortable-column-icon {
  color: $brown-univ !important;
}

.p-fileupload-choose {
  background: $blue-univ !important;
  color: #ffffff;
  border-color: $blue-univ !important;
}

.p-datatable-resizable-table > .p-datatable-thead > tr > th, .p-datatable-resizable-table > .p-datatable-tfoot > tr > td, .p-datatable-resizable-table > .p-datatable-tbody > tr > td {
  overflow: hidden;
  white-space: normal !important;
}
