@use '@angular/material' as mat;
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "fonts";
@import "colors";

$my-primary: mat.define-palette(mat.$blue-palette, 400);
$my-accent: mat.define-palette(mat.$brown-palette, 400);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  density: 0,
));

@include mat.all-component-themes($my-theme);

.mat-mdc-tab-body-content{
  overflow: hidden!important;
}

.mdc-tab {
  text-align: center;
  white-space: normal!important;
  cursor: pointer;
}

.mdc-text-field--disabled .mdc-text-field__input, .mdc-select--disabled, .mdc-checkbox--selected {
  color: rgba(0, 0, 0, 0.80) !important;
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.80) !important;
}

.mdc-floating-label {
  color: rgba(0, 0, 0, 0.50) !important;
}

.mat-mdc-option {
  font-size: $font-small !important;
}

.mat-mdc-dialog-container, .mat-mdc-dialog-container .mdc-dialog__container, .mat-mdc-dialog-container .mdc-dialog__surface {
  max-height: inherit;
  min-height: inherit;
  min-width: inherit;
  max-width: inherit;
  resize: both;
}

[mat-dialog-title] {
  padding: 10px 24px;
  display: block;
  background: $blue-univ;
  color: $font-light !important;
}

.mat-mdc-card{
  padding:10px!important;
}

.mat-mdc-snack-bar-container {
  &.notif-warn {
    --mdc-snackbar-container-color: $orange-univ;
    --mat-mdc-snack-bar-button-color: $font-light;
    --mdc-snackbar-supporting-text-color: $font-light;
  }

  &.notif-error {
    --mdc-snackbar-container-color: $red-univ;
    --mat-mdc-snack-bar-button-color: $font-light;
    --mdc-snackbar-supporting-text-color: $font-light;
  }

  &.notif-info {
    --mdc-snackbar-container-color: $blue-univ;
    --mat-mdc-snack-bar-button-color: $font-light;
    --mdc-snackbar-supporting-text-color: $font-light;
  }

  &.notif-success {
    --mdc-snackbar-container-color: $green-univ;
    --mat-mdc-snack-bar-button-color: $font-light;
    --mdc-snackbar-supporting-text-color: $font-light;
  }
  &.notif-success {
    --mdc-snackbar-container-color: $green-univ;
    --mat-mdc-snack-bar-button-color: $font-light;
    --mdc-snackbar-supporting-text-color:$font-light;
  }
}
