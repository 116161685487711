$font-body:#EAEAEA;
$font-card:#EAEAEA;

$blue-light-univ:#90caf9;
$blue-univ:#42a5f5;
$blue-dark-univ:#1565c0;
$brown-light-univ:#bcaaa4;
$brown-univ:#8d6e63;
$brown-dark-univ:#5d4037;
$red-light-univ:#fbe9e7;
$red-univ:#DD2C00;
$green-light-univ:#f1f8e9;
$green-univ:#388e3c;
$yellow-univ:#FFFF8D;
$orange-univ:#ffa726;
