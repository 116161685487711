@import "app/style/materials";
@import "app/style/primeng";
@import "app/style/variables";
@import "app/style/colors";
@import "app/style/fonts";
@import "@angular/material";

// HTML
html,
body {
  margin: 0;
  padding: 0;
  background-color: $font-body;
  color: $font-dark;
  font-size: $font-thin !important;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $font-grey;
}

a:hover{
  color: $brown-univ !important;
}

.font-primary{
  font-size: $font-small!important;
  color: $blue-univ !important;
}

.font-accent{
  font-size:$font-small!important;
  color: $brown-univ !important;
}


.title {
  display: contents !important;
  height: $top-height;
  background-color: transparent;
  color: $font-dark;
  line-height: $top-height;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: $font-hight;
  font-weight: 500;
  letter-spacing: 2px;
}

.active {
  color: $blue-univ !important;
}

.not-active {
  color: $orange-univ !important;
}

.version {
  font-size: $font-thin;
}

.alert-info {
  color: $blue-univ !important;
  background-color: inherit !important;
  margin: 25px 0 10px 40px;
  border: none !important;
}

.alert-warning {
  color: $orange-univ !important;
  background-color: inherit !important;
  margin: 25px 0 10px 40px;
  border: none !important;
}

.alert-danger {
  color: $red-univ !important;
  background-color: inherit !important;
  margin: 25px 0 10px 40px;
  border: none !important;
}

.disabled {
  pointer-events: none !important;
  cursor: default !important;
  opacity: 0.6 !important;
}

.buffer {
  margin: 15px;
}

.buffer-without-mb {
  margin: 15px 15px 0 15px;
}

.top-buffer {
  margin-top: 15px;
}

.left-buffer {
  margin-left: 15px;
}

.button {
  color: $blue-univ;
  font-size: $font-thin;
  right: 0;
  border: none;
  background: none;
}

.button:hover {
  color: $brown-univ;
}

.button:focus {
  outline: none;
}

.button:disabled,
.button[disabled] {
  color: rgba(0, 0, 0, 0.5);
}

.btn-white {
  color: $blue-univ;
}

.btn-white:hover {
  background-color: $brown-univ;
  color: $font-light;
}

.btn-brown {
  background-color: $brown-univ;
  color: $font-light;
}

.btn-brown:hover {
  background-color: $font-light;
  color: $brown-univ;
}

.chip-btn {
  margin-top: 9px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  background-color: $blue-univ;
  cursor: pointer;

  button {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    font-size: $font-small;
    color: $font-light;
  }
}

.chip-btn:hover {
  background-color: $brown-univ;

  button {
    font-size: $font-small;
    color: $font-light;
  }
}

.top-fixed {
  position: fixed !important;
  top: $top-height !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 500 !important;
}

.table-input {
  border: none;
  width: 100%;
  background-color: transparent;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: $brown-univ !important;
}

.notif-success {
  background-color: rgba(144, 238, 144, 0.7);
  color: $font-dark;
}

.notif-info {
  background-color: rgba(0, 191, 255, 0.7);
  color: $font-dark;
}

.notif-warn {
  background-color: rgba(255, 165, 0, 0.7);
  color: $font-dark;

}

.notif-error {
  background-color: rgba(255, 0, 0, 0.7);
  color: $font-dark;

}
